import { createSlice } from "@reduxjs/toolkit"
import { JsonFile } from "../components/transactions/newMassiveTransaction/interfaces/types"

export interface TransactionData {
  counterParty: {
    accountNumber: string
    name: string
    nickname: string
  }
  description: string
  amount: string
  taxAmount: string
  scheduledDate: string | null
  id?: string
  outMarketImmediate: boolean | null
}
interface InitialState {
  originAccount: {
    cvu: string
    description: string
    accountId: string
    email: string
    moneyMarketSubscriber: boolean
  }
  transactionsData: TransactionData[]
  jsonFile: JsonFile[]
  transactionsIds: string[]
  token: string
  bulkId: string
  outMarketImmediate?: boolean
  detailOfTransactionsAlreadyCreated: boolean
  actionInTheDetailScreen:
    | "AUTHORIZE"
    | "REJECTION"
    | "AUTHORIZE_EMAIL"
    | "CONFIRM"
    | "ONLY_SHOW_DETAIL"
    | "DETAIL_WITHOUT_FUNDS"
    | null
  massiveTransactionModal: {
    isOpen: boolean
    action:
      | "PRE_AUTHORIZE"
      | "PRE_REJECTION"
      | "CONFIRM_REJECTION"
      | "AUTHORIZE_EMAIL"
      | "AUTHORIZE"
      | "CONFIRM"
      | "SUCCESSFUL_AUTHORIZATION"
      | "DETAIL_WITHOUT_FUNDS"
      | "POSTPONED_AUTHORIZATION"
      | "SUCCESSFUL_AUTHORIZATION_EMAIL"
      | "SUCCESSFUL_CONFIRMATION"
      | "SUCCESSFUL_RESEND_TOKEN"
      | "OVERNIGHT_SELECTOR"
      | null
  }
}

const initialState: InitialState = {
  originAccount: {
    cvu: "",
    description: "",
    accountId: "",
    email: "",
    moneyMarketSubscriber: false,
  },
  transactionsData: [],
  jsonFile: [],
  transactionsIds: [],
  token: "",
  bulkId: "",
  detailOfTransactionsAlreadyCreated: false,
  actionInTheDetailScreen: null,
  massiveTransactionModal: {
    isOpen: false,
    action: null,
  },
}

const newMassiveTransactionForm = createSlice({
  name: "Massive Transaction Form",
  initialState,
  reducers: {
    onUpdateMassiveTransaction: (state, action) => {
      return { ...state, ...action.payload }
    },
    onResetMassiveTransactionState: () => {
      return initialState
    },
  },
})

export const { onUpdateMassiveTransaction, onResetMassiveTransactionState } =
  newMassiveTransactionForm.actions

export default newMassiveTransactionForm.reducer
