import { createSlice } from "@reduxjs/toolkit"
import {
  PaymentLinkActionModal,
  PaymentMethodType,
} from "../components/paymentLinks/interfaces/types"

interface InitialState {
  data: {
    amount: string
    receiverAccount: string
    title: string
    description: string
    paymentType: PaymentMethodType[]
    expirationDate: string
    paymentLinkId: string
    maxInstallments: string
  }
  open: boolean
  action: PaymentLinkActionModal
}

const initialState: InitialState = {
  data: {
    amount: "",
    receiverAccount: "",
    title: "",
    description: "",
    paymentType: ["DEBIT", "CREDIT", "PREPAID/FOREIGN"],
    expirationDate: "",
    paymentLinkId: "",
    maxInstallments: "",
  },
  open: false,
  action: "NEW_PAYMENT_LINK",
}

const paymentLinks = createSlice({
  name: "paymentLinks",
  initialState,
  reducers: {
    onUpdatePaymentLinks: (state, action) => {
      return { ...state, ...action.payload }
    },
    onResetPaymentLinks: () => {
      return { ...initialState }
    },
  },
})

export const { onResetPaymentLinks, onUpdatePaymentLinks } =
  paymentLinks.actions

export default paymentLinks.reducer
